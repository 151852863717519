import { route } from 'preact-router';

import { validateToken as validateTokenApi } from './api';
import { ENABLE_PASSWORD_RESET_BUTTON, INVALID_TOKEN, SHOW_PASSWORD_RESET_MODAL, TOKEN } from './types';

export const showModal = (showModal, email = '') => ({
  type: SHOW_PASSWORD_RESET_MODAL,
  payload: { showModal, email },
});

export const enableSubmit = (submitEnabled) => ({
  type: ENABLE_PASSWORD_RESET_BUTTON,
  payload: { submitEnabled },
});

export const setToken = (token) => ({
  type: TOKEN,
  payload: { token },
});

export const setInvalidToken = (invalidToken) => ({
  type: INVALID_TOKEN,
  payload: { invalidToken },
});

export const validateToken = (token) => async (dispatch) => {
  try {
    dispatch(setToken(token));
    const { isValid } = await validateTokenApi(token);
    if (!isValid) {
      dispatch(showModal(true));
      route('/login', true);
    } else {
      route('/passwordReset');
    }
    dispatch(setInvalidToken(!isValid));
  } catch (err) {
    console.error('Error while validating token', err);
    dispatch(setInvalidToken(false));
  }
};
